
.App-footer {
	clear: both;
	bottom: 0;
	background-color: white;
}

.content {
	margin-bottom: 8rem;
}

.nameHead {
	background-color: #efefef;
	padding-top: 3rem;
	text-align: center;
	font-size: 8rem;
	font-family: MelindaScript;
}

@media screen and (max-width: 700px) {
	.nameHead {
		font-size: 5rem;
	}
}

@media screen and (max-width: 500px) {
	.nameHead {
		font-size: 4rem;
	}
}

.App-header {
	background-color: #efefef;
}

.App-header li {
	padding-left: 2rem;
	text-transform: uppercase;
}
.App-header li a {
	color: black;
}

.sideProduct.product {
	position: absolute;
	top: 15rem;
	transform: scale(0.7);
	width: 25rem;
}

.sideProduct.product > .arrow {
	display: none;
}

@media screen and (max-width: 1800px) {
	.sideProduct.product {
		position: relative;
		width: 100%;
		top: 0;
	}
	.sideProduct.product .card-text,
	.sideProduct.product img {
		display: none;
	}
	.sideProduct.product .priceWrapper {
		height: 5rem;
	}
}
