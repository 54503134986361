.homepage {
	padding-top: 2rem;
}

.homepage .product {
	width: 45rem;
	margin: auto;
}

.homepage .image {
	width: 50%;
	float: right;
	margin-left: 30px;
}

@media screen and (max-width: 992px) {
	.homepage {
		text-align: center;
	}

	.homepage h2 {
		margin-top: 20px;
	}

	.homepage .image {
		width: 100%;
		float: none;
		margin-left: auto;
	}
}
