
.contact {
	text-align: left;
}

.contact h2 {
	padding-top: 5rem;
	text-transform: uppercase;
	font-weight: normal;
	font-size: 150%;
}

.contact .contactImage {
	width: 50%;
	float: right;
	margin-top: 5rem;
}

.contact .table {
	width: 45%;
	margin-right: 1rem;
}

.contact .contactImage.before {
	display: block;
}

.contact .contactImage.after {
	display: none;
}

.contact .facebook {
	padding-left: 0;
}

@media screen and (max-width: 800px) {
	.contact .contactImage {
		float: none;
		width: 100%;
	}
	.contact .table {
		width: 100%;
	}
	
	.contact .contactImage.before {
		display: none;
	}

	.contact .contactImage.after {
		display: block;
	}
}
